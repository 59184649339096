
.card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  padding-top: 15px;
}


.card-title {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.divbtn {
  margin-bottom: 1rem;
}



.table {
  /* width: 10%; */
  margin-bottom: 1rem;
  color: #212529;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table thead {
  background-color: #343a40;
  color: #fff;
}

.table tbody {
  color: #212529;
}

.table td {
  padding: 0.75rem;
}

.table tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.05);
}

.video-container {
  width: 40px;

}

.video-player {

  width: 70px;
  height: 70px;
}

.dov_prod{
  margin-left: 10%;
  
  
}