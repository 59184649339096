body {
  height: 100vh;
}

.container {
  margin-top: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 10px;
}

.text {
  color: #3c009d;
  font-size: 28px;
  font-weight: 700;
}

.underline {
  width: 56px;
  height: 5px;
  background: #3c009d;
  border-radius: 9px;
}

.inputs {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 480px;
  height: 60px;
  background: #eaeaea;
  border-radius: 11px;
}

.input img {
  margin: 0px 30px;
}

.input input {
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: #797979;
  font-size: 18px;
}

.forgot-password {
  padding-left: 62px;
  margin-top: 27px;
  color: #797979;
  font-size: 18px;
}

.forgot-password span {
  color: #4c00b4;
  cursor: pointer;
}

.submit-container button {
  display: flex;
  gap: 30px;
  margin: 60px auto;
  background: #f1356d;
  border: 0;
  padding: 11px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 15%;
  justify-content: center;
  font-size: 20px;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 59px;
  color: #fff;
  background: #4c00b4;
  border-radius: 50px;
  font-size: 19px;
  font-weight: 700;
  cursor: pointer;
}

.gray {
  background: #EAEAEA;
  color: #676767;
}

