.flex-horizontal {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  /* align-items: center; */
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}


.image-container {
  flex: 1;
  /* margin-right: 20px; */
}

.product-image {
  width: 350px;
}

.thumbnail-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  width: 83%;
}

.thumbnail {
  max-width: 95px;
  height: auto;
  margin: 5px;
  cursor: pointer;
}

.about-container {
  flex: 1;  
  justify-content: center;
  width: 480px;
}

 table {
   width: 100%;
   border-collapse: collapse;
   margin-bottom: 20px;
   margin-top: 30px;
 }

 th,td {
   border: 1px solid #ddd;
   padding: 8px;
   text-align: left;
 }

 th {
 }

 tr:hover {
   background-color: #f5f5f5;
 }

.about-container .video-container .video-player {
  max-width: 280px;
  max-height: 200px;
  margin-left: 10px;
}

 #fileDisplayArea {
  margin-top: 20px;
 }

 .video_player{
  width: 400px;
  height: 300px;
 }

 
.product-brand {
  width: 110px;
}