
.santehplast-card {
    height: 300px;    
}

.santehplast-card:hover {
    border: 2px solid #ddd;    
}

.santehplast-card .card-text {
    /* overflow: hidden; */
    line-height: 1.3;
    display: inline-block;
    vertical-align: middle;
}

.santehplast-card .card-body {
    padding:5px;
    height: 120px;
    line-height: 110px;
    text-align: center;
}

.santehplast-card .card-image-class {
    margin:auto;
    display: block;
}

.santehplast-card .product-image {
    height: 170px;
    overflow: hidden;
    width: 100%;
}

.santehplast-card .product-brand {
    width: 70px;
    margin: auto;
    display: block;
}

.product-filter {
    text-align: center;
}
.product-filter button {
    font: 10pt/1.5 Verdana, Tahoma, Arial, sans-serif;

}
.product-filter button:hover {
    background-color: rgb(250, 239, 239);

}
a {
    text-decoration: none;
}

.slider_wrap{
    /* height: 220px;
    border-radius: 5px; */
}
.slider img{
    border-radius: 5px;
    /* margin-bottom: 30px; */
}
/* $btn-hover-bg-shade-amount: 2%; */
ol {
    list-style-type: none
}
.carousel-indicators li {
    background-color: grey;
    height: 5px;
    width: 20px;
    z-index: 5 !important;
    margin: 0 5px;
    cursor: pointer;
  }
  .carousel-indicators li.active {
    background-color: white;
    height: 5px;
    width: 20px;
    z-index: 5 !important;
    padding: 0 5px;
    cursor: pointer;
  }