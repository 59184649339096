.pdfFile{
  font-size: 36px;
    color: red;
    
}

.file_name{
  margin-left: 10px;
  display:inline-flex;
  display: flex;
  
  
}

.alert_red {
  margin-top: 10px;
}

.UploadFilebutton{
  margin-top: 18px;
}

.deleteButton{
  position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    
}