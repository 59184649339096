
.container select {
  width: 100%;
  padding: 6px 10px;
    margin: 10px 0;
    border: 2px solid #ddd;
    box-sizing: border-box;
    display: block;
}


.container label {
  text-align: left;
  display: block;
}

.container h2 {
  font-size: 20px;
  color: #ec1c1c;
  margin-bottom: 30px;
}

.container textarea {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 2px solid #ddd;
  box-sizing: border-box;
  /* display: block; */
}



.container .checkbox {
  display: flex;
  justify-content: end;

}


.form-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.submit-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-button {
  
  cursor: pointer;
}

.save-button {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px;
  /* margin-top: 10px; */
}

.back-button {
  background: #dc3545;
  color: #fff;
  text-decoration: none;
  border-radius: 0.7rem;
  padding: 10px;
  margin-top: 7px;
}