

.category {
  /* max-width: 80%; */
  /* width: 100%;
  margin: 40px 20%; */
  /* text-align: center; */
  font-weight: bold;
  /* display: flex; */
  /* column-gap: 10%; */
 

}


.category label {
  text-align: left;
  display: block;
}

.category h2 {
  font-size: 20px;
  color: #3a232a;
  margin-bottom: 30px;
}

.category input {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 2px solid #ddd;
  box-sizing: border-box;
  display: block;

}

.category textarea {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 2px solid #ddd;
  box-sizing: border-box;
  display: block;
}

.category button {
  background: #3580f1;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
}

.category .why {
  
  margin-top: 10%;
  
  
}