.admin-category {
  display: flex;
  /* height: 100vh; */
}
.sure ul {


  list-style-type: none;
  padding: 0;
  display: flex;
  /* flex-direction: column; */
  /* width: fit-content; */
  /* margin-right: 40%; */

}

.admin-category li {
  margin-bottom: 10px;
  display: inline-block;
  margin: 10px;
  display: flex;
}

.admin-category li a {
  text-decoration: none;
  display: inline-block;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  text-align: center;
  color: #333;
  font-weight: bold;
  /* flex-grow: 1; */
  width: 100%;
}

.admin-category li a:hover {
  background-color: #f0f0f0;
}


.admin-panel {
  min-height: 500px;
}