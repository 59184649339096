
 .cat {

  margin: auto;
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  
  text-align: center;
}

.cat .cards_cat {
  width: 80%;
  height: 50vh;
  padding: 5px;
  margin: 5px 5px;
  
}

.cat .cards_cat:hover {
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.image_box_cat img {
  width: 150px;
  height: 150px;
  margin: 10px auto 0px auto;
}


.details1_cat p{
  /* align-items: center; */
  text-align: center;
  text-decoration: none;
}
.details1_cat p:nth-child(1) {
  font-weight: bold;
}

.details1_cat p:nth-child(2) {
  color: grey;
  font-size: 0.8rem;
}

.details1_cat p:nth-child(3) {
  font-weight: bold;
  color: grey;
}

.category-tab-buttons li { 
  display: inline;
}

.category-tab-buttons li {
  margin-bottom: 5px;
  display: inline;
  margin: 5px;
}

.category-tab-buttons li a {
  text-decoration: none;
  padding: 5px;
  border: 1px solid #333;
  border-radius: 5px;
  text-align: center;
  color: #333;
  font-weight: bold;
  /* flex-grow: 1; */
  width: 100%;
  color:black;
}

.category-tab-buttons li a:hover {
  background-color: #f0f0f0;
  color:black;
}



.category-tab-buttons li {
  margin-bottom: 10px;
  
}