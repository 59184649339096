
header > ul li {
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  padding: 17px 5px;
  margin: 0 auto;
}

.search-input {
  min-width: 300px;
  margin: 10px;
}

.logo {
  width: 200px;
}

.page-title {
  color: #ec1c1c;
  text-align:center;
}
.input-group input{
  font:10pt/1.5 Verdana, Tahoma, Arial, sans-serif;
}

.nav-link  {
  color:black;
  text-transform: uppercase;
  padding: 18px;
}
.sign-in-button {
  width: 38px;
  height: 38px;
  margin-top:7px;
  margin-left:17px;
}
.logo-in-mobile {
  margin-left: 4px;
  width: 200px;
}